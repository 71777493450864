<template>
  <div>
    <div class="b-row mt-2">
      <div class="b-col" style="margin: 2% !important">
        <h5>Weight Data</h5>
        <h2 class="mt-2">Ağırlık: {{ weightData }}</h2>
      </div>
    </div>
    <b-row>
      <b-col xl="4" xs="0">
        <b-button variant="warning" @click="connectSerial" block
          >Bağlan</b-button
        >
      </b-col>

      <b-col xl="4" xs="0">
        <b-button variant="success" @click="sendCommand" block>Tart</b-button>
      </b-col>
      <b-col xl="4" xs="0">
        <b-button variant="danger" @click="disconnectSerial" block
          >Bağlantıyı Kes</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  },
  data() {
    return {
      weightData: "0",
      port: null,
      reader: null,
      writer: null,
    };
  },
  methods: {
    async connectSerial() {
      try {
        this.port = await navigator.serial.requestPort();

        await this.port.open({ baudRate: 9600 });

        const textDecoder = new TextDecoderStream();
        const readableStreamClosed = this.port.readable.pipeTo(
          textDecoder.writable
        );
        this.reader = textDecoder.readable.getReader();
        console.log("readeer", this.reader);

        const textEncoder = new TextEncoderStream();
        const writableStreamClosed = textEncoder.readable.pipeTo(
          this.port.writable
        );
        this.writer = textEncoder.writable.getWriter();
        console.log("writer", this.writer);
        this.readSerialData();
      } catch (error) {
        console.error("There was an error opening the serial port:", error);
      }
    },
    async readSerialData() {
      try {
        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            this.reader.releaseLock();
            break;
          }
          if (value) {
            console.log("value", value);
            this.weightData = value.trim();
          }
        }
      } catch (error) {
        console.error("Error reading serial data:", error);
      }
    },
    async sendCommand() {
      try {
        if (this.writer) {
          await this.writer.write("Tart");
        }
      } catch (error) {
        console.error("Error sending command to serial port:", error);
      }
    },
    async disconnectSerial() {
      try {
        if (this.reader) {
          await this.reader.cancel();
          this.reader.releaseLock();
          this.reader = null;
        }
        if (this.writer) {
          await this.writer.close();
          this.writer.releaseLock();
          this.writer = null;
        }
        if (this.port) {
          await this.port.close();
          this.port = null;
        }
        console.log("Serial port disconnected.");
      } catch (error) {
        console.error("There was an error closing the serial port:", error);
      }
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
}
</style>
